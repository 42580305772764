import { Container, Image, Button, Offcanvas, Col, Row, Spinner } from 'react-bootstrap';
import { fetchProjectsData } from '../../sanityQueries';
import { useState, useEffect } from 'react';
import { urlFor } from '../../sanityClient';

import './Projects-View.scss';

export const ProjectView = () => {
    const [projects, setProjects] = useState([]);
    const [chosenApp, setChosenApp] = useState(null);
    const [show, setShow] = useState(false);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [allTechnologies, setAllTechnologies] = useState([]);
    const [currentFilter, setCurrentFilter] = useState([]);
    const [clickedButtons, setClickedButtons] = useState([]);
    const [hoveredProjects, setHoveredProjects] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState(true);
    const [gifLoading, setGifLoading] = useState(true);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [countHoveredNext, setCountHoveredNext] = useState(0);
    const [countHoveredPrev, setCountHoveredPrev] = useState(0);

    useEffect(() => {
        setLoading(true); // Set loading true before fetching
        fetchProjectsData().then((data) => {
            const sortedData = data.sort((a, b) => new Date(b.developmentDate) - new Date(a.developmentDate));
            setProjects(sortedData);
            setFilteredProjects(sortedData);
            setLoading(false);

            // Merge all technologies after fetching projects
            const allTechnologiesMerged = [];
            for (let i = 0; i < data.length; i++) {
                allTechnologiesMerged.push(...data[i].technologies);
            }
            const result = [...new Set(allTechnologiesMerged)].sort();
            setAllTechnologies(result);
            setClickedButtons(Array(result.length).fill(true));

            // Set currentFilter to allTechnologies
            setCurrentFilter(result); // Set to the merged technologies directly here


            setLoading(false);
        });
    }, []);

    const checkIfMobile = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 720) {
            setIsMobile(true);
            setItemsPerPage(3);
        } else {
            setItemsPerPage(4);
            setIsMobile(false);
        }
    };

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    useEffect(() => {
        const filterResult = currentFilter.length
            ? projects.filter(item => item.technologies.some(technology => currentFilter.includes(technology)))
            : projects;
        setFilteredProjects(filterResult);
    }, [currentFilter, projects]);

    const handleShow = (project) => {
        setSelectedImageIndex(0);
        setChosenApp(project);
        setShow(true);
        setGifLoading(true);
    };

    const handleClose = () => {
        setChosenApp(null);
        setShow(false);
    };

    const handleFilter = (technology, index) => {
        const isIncluded = currentFilter.includes(technology);
        const updatedFilter = isIncluded
            ? currentFilter.filter(tech => tech !== technology)
            : [...currentFilter, technology].sort();

        setCurrentFilter(updatedFilter);
        setClickedButtons(prev => {
            const updatedButtons = [...prev];
            updatedButtons[index] = !updatedButtons[index];
            return updatedButtons;
        });
    };

    const handleClear = () => {
        setCurrentFilter([]);
        setClickedButtons(Array(allTechnologies.length).fill(false));
        setFilteredProjects(projects);
        setCurrentIndex(0);
    };

    const handleShowAll = () => {
        setCurrentFilter(allTechnologies);
        setClickedButtons(Array(allTechnologies.length).fill(true));
        setFilteredProjects(projects);
        setCurrentIndex(0);
    };

    const handleNext = () => {
        setLoading(true); // Set loading true before changing the index
        setCurrentIndex((prevIndex) => {
            const newIndex = Math.min(prevIndex + 1, totalPages - 1);
            setLoading(false); // Set loading false after changing the index
            return newIndex;
        });
    };

    const handlePrev = () => {
        setLoading(true); // Set loading true before changing the index
        setCurrentIndex((prevIndex) => {
            const newIndex = Math.max(prevIndex - 1, 0);
            setLoading(false); // Set loading false after changing the index
            return newIndex;
        });
    };

    const handleHoveredProjects = (technology) => {
        const filterResult = projects.filter(item => item.technologies.includes(technology));
        setHoveredProjects(filterResult);

    }

    useEffect(() => {
        let countNext = 0;
        let countPrev = 0;
        hoveredProjects.forEach(project => {
            if (filteredProjects.indexOf(project) >= (currentIndex + 1) * itemsPerPage) {
                countNext++;
            } else if (filteredProjects.indexOf(project) < currentIndex * itemsPerPage) {
                countPrev++;
            } else {
                return;
            }
        });
        setCountHoveredNext(countNext);
        setCountHoveredPrev(countPrev);
    }, [hoveredProjects, currentIndex, itemsPerPage, filteredProjects]);

    const handleMouseEnter = (technology) => {
        handleHoveredProjects(technology);
    }

    const handleMouseLeave = () => {
        handleHoveredProjects([]);
    }

    const handlePreviewChange = (index) => {
        setSelectedImageIndex(index);
    }

    const renderProjects = () => {
        const startIndex = currentIndex * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, filteredProjects.length);

        return filteredProjects.slice(startIndex, endIndex).map((project, index) => (
            <Container key={index}>
                {loading
                    ?
                    <Spinner animation="border" variant="light" className="image-loader" /> // Spinner for loading image
                    :
                    <Container>
                        <h5 className="text-center">{new Date(project.developmentDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h5>
                        <Button
                            className={`m-1 btn-dark project-btn ${hoveredProjects.some(proj => proj.title === project.title) && "highlighted"}`}
                            onClick={() => handleShow(project)}
                        >
                            {!imageLoading ? (
                                <Image
                                    className={`project-image ${imageLoading ? 'image-loading' : ''}`}
                                    src={urlFor(project.imageUrl).url()}
                                    alt={`Project ${index + startIndex + 1}`}
                                    rounded
                                    onLoad={() => setImageLoading(false)} // Set image loading false when image loads
                                />
                            ) : (
                                <Spinner animation="border" variant="light" className="image-loader" /> // Spinner for loading image
                            )}
                            <Container className="caption">
                                <Container className="main-title"><h5>{project.title}</h5></Container>
                            </Container>
                        </Button>
                    </Container>

                }

            </Container>
        ));
    };

    return (
        <Container id="main-projects-container">
            <Row>
                {!isMobile && (
                    <Col md={4}>
                        <h1 className="section-subtitle">Skills:</h1>
                        <Row id="skills-section">
                            <Row className="button-skills-list">
                                {allTechnologies.map((technology, index) => (
                                    <Button
                                        key={index}
                                        className={clickedButtons[index] ? "custom-btn m-1 button-clicked" : "custom-btn m-1 btn-light"}
                                        onMouseEnter={() => handleMouseEnter(technology)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => handleFilter(technology, index)}
                                    >
                                        {technology}
                                    </Button>
                                ))}
                            </Row>
                            <Button
                                className='clear-skills custom-btn m-1 p-0 w-100 btn-info'
                                onClick={handleClear}
                            >
                                <h4>Clear skills</h4>
                            </Button>
                        </Row>
                    </Col>
                )}
                <Col md={8}>
                    <h1 className="section-subtitle">Projects:</h1>
                    {currentFilter.length === 0 && !isMobile ? (
                        <Container className="no-skills-section">
                            <h4 className="no-skills-text">Please select a skill:</h4>
                            <Button className="show-all-button custom-btn m-1" variant="info" onClick={handleShowAll}>
                                <h4>Show all</h4>
                            </Button>
                        </Container>
                    ) : (
                        <Container id="projects-carousel" className="carousel" data-ride="carousel">
                            {filteredProjects.length > itemsPerPage && currentIndex > 0 && (
                                <Button className="carousel-control-prev bg-transparent" onClick={handlePrev} role="button">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Prev</span>
                                    <p className={`preview-popup ${countHoveredPrev > 0 && 'preview-revealed'}`}>{countHoveredPrev}</p>
                                </Button>
                            )}
                            <Container className="carousel-inner">
                                <Row id="projects-section">
                                    {renderProjects()}
                                </Row>
                            </Container>
                            {filteredProjects.length > itemsPerPage && currentIndex < totalPages - 1 && (
                                <Button className="carousel-control-next bg-transparent" onClick={handleNext} role="button">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                    <p className={`preview-popup ${countHoveredNext > 0 && 'preview-revealed'}`}>{countHoveredNext}</p>
                                </Button>
                            )}
                        </Container>
                    )}
                    {isMobile ? null :
                        <Offcanvas className="image-sidebar w-75 p-0" show={show} onHide={handleClose} placement="start">
                            {chosenApp && (
                                <Container>
                                    <Offcanvas.Body>
                                        <Container className="w-100 pr-5 pb-5">
                                            <Row>
                                                {gifLoading && (
                                                    <Spinner animation="border" variant="light" className="image-loader" /> // Spinner for loading image
                                                )}
                                                <Image
                                                    className={`offcanvas-gif ${gifLoading ? 'gif-loading' : ''}`}
                                                    src={
                                                        selectedImageIndex === 0 // Check if no image has been selected
                                                            ? urlFor(chosenApp.gifUrl).url() // Show the GIF if no thumbnail is clicked
                                                            : urlFor(chosenApp.optionalFields.extraImages[selectedImageIndex - 1]).url() // Show the selected thumbnail
                                                    }
                                                    onLoad={() => setGifLoading(false)}
                                                />
                                            </Row>
                                            {chosenApp.optionalFields.extraImages &&
                                                <Row className="mt-3 preview-buttons-section">
                                                    {[
                                                        { gifUrl: chosenApp.gifUrl }, // Add gifUrl as the first item in the array
                                                        ...chosenApp.optionalFields.extraImages.map((image, index) => ({ image, index })) // Map extra images with index
                                                    ].map((item, index) => (
                                                        <Button key={index} className="preview-buttons" onClick={() => handlePreviewChange(index)}>
                                                            <Image
                                                                src={item.gifUrl ? urlFor(item.gifUrl).url() : urlFor(item.image).url()} // Use gifUrl if it exists
                                                                alt={item.gifUrl ? 'GIF Image' : `Screenshot ${index}`} // Set alt text accordingly
                                                                rounded
                                                                fluid
                                                            />
                                                        </Button>
                                                    ))}
                                                </Row>
                                            }
                                        </Container>
                                    </Offcanvas.Body>
                                </Container>
                            )}
                        </Offcanvas>
                    }
                    <Offcanvas className="info-sidebar p-0" show={show} onHide={handleClose} placement="end">
                        {chosenApp && (
                            <Container>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title><h4>{chosenApp.title}</h4></Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <h5>{chosenApp.subtitle}</h5>
                                                <p>{chosenApp.description}</p>
                                            </Col>
                                            <Col>
                                                <h5>Technologies used:</h5>
                                                <p>{chosenApp.technologies.join(', ')}</p>
                                                <h5>Developed on: </h5><p>{new Date(chosenApp.developmentDate).toDateString()}</p>
                                            </Col>
                                            {chosenApp.optionalFields.credentials && (
                                                <Col>
                                                    <h5>Login with:</h5>
                                                    <p>Username: {chosenApp.optionalFields.credentials.username}</p>
                                                    <p>Password: {chosenApp.optionalFields.credentials.password}</p>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            {(chosenApp.optionalFields.Links && chosenApp.optionalFields.Links.repoLink) && <Button className="mb-2 offcanvas-button" href={chosenApp.optionalFields.Links.repoLink} target="_blank">Repository</Button>}
                                            {(chosenApp.optionalFields.Links && chosenApp.optionalFields.Links.liveLink) && <Button className="mb-2 offcanvas-button" href={chosenApp.optionalFields.Links.liveLink} target="_blank">Live Link</Button>}
                                            {chosenApp.optionalFields.caseStudy && <Button className="mb-2 offcanvas-button" href={chosenApp.caseStudy} target="_blank">Case Study</Button>}
                                            {chosenApp.optionalFields.shoutOuts && <Button className="mb-2 offcanvas-button" href={chosenApp.optionalFields.shoutOuts.shoutOutLink} target="_blank">In partnership with {chosenApp.optionalFields.shoutOuts.companyName}</Button>}
                                        </Row>
                                    </Container>
                                </Offcanvas.Body>
                            </Container>
                        )}
                    </Offcanvas>
                </Col>
            </Row>
        </Container>
    );
};
