// src/sanityClient.js
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const client = createClient({
  projectId: 'p140bzou', // Use the same projectId as in sanity.cli.js
  dataset: 'production', // Use the same dataset as in sanity.cli.js
  useCdn: true,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);


export default client;